import styled from 'styled-components';
import { Box } from '../Box';
import { StyledContainerProps } from './HeaderSection.d';
import { Typography } from '../Typography';
import { COLORS } from 'theme/colors';

export const StyledContainer = styled(Box)<StyledContainerProps>`
  width: 100%;
  min-height: 51vh;
  background-image: url(${({ backgroundUrl }) => backgroundUrl});
  background-size: cover;
`;

export const StyledHeader = styled(Typography)`
  font-size: 64px;
  color: ${COLORS.background.secondary};
  margin-bottom: 24px;
`;

export const StyledSubHeader = styled(Typography)`
  font-size: 18px;
  line-height: 35px;
  color: ${COLORS.background.secondary};
`;
