import { FC } from 'react';
import {
  StyledAboutUsContainer,
  StyledContainer,
  StyledHeader,
  StyledInnerContainer,
  StyledSubHeader,
} from './MainScreen.styled';
import { Header } from 'components/common/Header';
import { Box } from '../../common/Box';
import { Button } from '../../common/Button';
import { ButtonVariant } from '../../common/Button/Button.enums';
import { Typography } from '../../common/Typography';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { COLORS } from '../../../theme/colors';
import { tags } from './config';
import { Chip } from '../../common/Chip';
import { hexToRGB } from '../../../utils/common';
import { useNavigate } from 'react-router';
import { AppPaths } from 'urls/frontend';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import {
  ContactAboutUsAction,
  ContactCategory,
  ContactMainSectionAction,
} from 'utils/google-analytics/events/contacts';
import { AboutUsCategory } from 'utils/google-analytics/events/aboutUs';

export const MainScreen: FC = () => {
  const navigate = useNavigate();

  const onContactSalesClick = () => {
    TrackGoogleAnalyticsEvent(
      ContactCategory,
      ContactMainSectionAction,
      window.location.pathname,
    );
    navigate(AppPaths.contacts);
  };

  const onAboutUsClick = () => {
    TrackGoogleAnalyticsEvent(
      AboutUsCategory,
      ContactAboutUsAction,
      window.location.pathname,
    );
    navigate(AppPaths.aboutUs);
  };

  return (
    <StyledContainer>
      <Header variant="secondary" />
      <StyledInnerContainer display="grid" alignItems="end">
        <Box
          pl={8}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          style={{ height: '100%' }}
        >
          <Box style={{ paddingTop: 'calc(calc(-300px + 100vh - 50%))' }}>
            <StyledHeader variant="h1">Smarte Fondsselektion</StyledHeader>
            <StyledSubHeader variant="body">
              Mehrwert durch unabhängige Fondsanalyse Innovativ & Bewährt
            </StyledSubHeader>
            <Box display="flex" gap={1.1} alignItems="center" pt={6}>
              <Button
                variant={ButtonVariant.OUTLINED}
                onClick={onContactSalesClick}
              >
                Kontaktieren Sie uns
              </Button>
              {/* <Button variant={ButtonVariant.CONTAINED}>
                Get started for free
              </Button> */}
            </Box>
          </Box>
          <Box pt={3} pb={4.1} display="flex" gap={1} flexWrap="wrap">
            {tags.map((tag) => (
              <Chip
                label={tag}
                customColor={COLORS.background.secondary}
                customBackground={hexToRGB(COLORS.background.secondary, '0.25')}
                customBorderBolor="none"
                customSize="large"
              />
            ))}
          </Box>
        </Box>
        <StyledAboutUsContainer
          py={5}
          pl={5}
          pr={8}
          display="flex"
          flexDirection="column"
          gap={5}
        >
          <Typography variant="body">
            Seit über drei Jahrzehnten bietet FondsConsult als eigenständiges
            Beratungsinstitut fundierte Kompetenz in der Investmentwelt. Unsere
            Expertise umfasst die sorgfältige Analyse und Auswahl von
            Investmentfonds aus allen relevanten Anlageklassen. Im Zentrum
            unserer Tätigkeit steht die umfassende Beratung für ein breites
            Spektrum an Finanzakteuren: von Banken in ihrem Depot A- und
            B-Geschäft über Versicherungen und Fonds-Distributoren bis hin zu
            wichtigen Entscheidungsträgern im Kapitalanlage-Bereich. Erfahren
            Sie mehr über uns.
          </Typography>
          <Button
            variant={ButtonVariant.SECONDARY}
            rightIcon={<ArrowIcon stroke={COLORS.info.selectedBorder} />}
            onClick={onAboutUsClick}
          >
            Über uns
          </Button>
        </StyledAboutUsContainer>
      </StyledInnerContainer>
    </StyledContainer>
  );
};
