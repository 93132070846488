import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';

export const StyledHeader = styled(Typography)`
  font-size: 48px;
  margin-bottom: 24px;
`;

export const StyledTextContainer = styled(Box)`
  width: 67%;
  margin: 0 auto;
`;

export const StyledTeamMembersContainer = styled(Box)`
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: repeat(3, auto);
  grid-gap: 40px 20px;
`;

export const StyledTypography = styled(Typography)`
  font-size: 16px;
  line-height: 29px;
`;
