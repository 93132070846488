import styled from 'styled-components';
import { Box } from '../../common/Box';
import { COLORS } from 'theme/colors';
import BackgroundImage from 'assets/backgrounds/studies.png';
import { Typography } from 'components/common/Typography';

export const StyledContainer = styled(Box)`
  grid-template-columns: 1fr 1fr;
  min-height: calc(100vh - 113px);

  border-top: 1px solid ${COLORS.stroke.primary};
`;

export const StyledLeftContainer = styled(Box)`
  border-radius: 8px;
  background-image: url(${BackgroundImage});
  background-size: cover;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const StyledRightContainer = styled(Box)`
  border-radius: 8px;
  background-color: ${COLORS.background.neutral};

  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const StyledTypography = styled(Typography)`
  font-size: 16px;
  line-height: 29px;
`;
