import { FC } from 'react';
import { HeaderSectionProps } from './HeaderSection.d';
import {
  StyledContainer,
  StyledHeader,
  StyledSubHeader,
} from './HeaderSection.styled';
import { Header } from '../Header/Header';
import { Box } from '../Box';

export const HeaderSection: FC<HeaderSectionProps> = ({
  label,
  description,
  secondDescription,
  backgroundUrl,
}) => {
  return (
    <StyledContainer backgroundUrl={backgroundUrl}>
      <Header variant="secondary" />
      <Box pt={7} display="flex" flexDirection="column" alignItems="center">
        <StyledHeader variant="h1">{label}</StyledHeader>
        <StyledSubHeader variant="body">{description}</StyledSubHeader>
        {secondDescription && (
          <StyledSubHeader variant="body">{secondDescription}</StyledSubHeader>
        )}
      </Box>
    </StyledContainer>
  );
};
