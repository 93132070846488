import { FC } from 'react';
import { Box } from '../../common/Box';
import { StyledContainer, StyledRightContainer } from './OurApproach.styled';
import { Typography } from '../../common/Typography';
import { Button } from '../../common/Button';
import { ButtonVariant } from '../../common/Button/Button.enums';
import { useNavigate } from 'react-router';
import { AppPaths } from 'urls/frontend';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import {
  ContactCategory,
  ContactOurApproachAction,
} from 'utils/google-analytics/events/contacts';

export const OurApproach: FC = () => {
  const navigate = useNavigate();

  const onContactSalesClick = () => {
    TrackGoogleAnalyticsEvent(
      ContactCategory,
      ContactOurApproachAction,
      window.location.pathname,
    );
    navigate(AppPaths.contacts);
  };

  return (
    <StyledContainer display="grid" alignItems="center" className="about-us">
      <Box pl={8} pr={14.5} display="flex" flexDirection="column" gap={3}>
        <Typography variant="h2">Unser Ansatz</Typography>
        <Typography variant="body">
          Unser Ansatz bei FondsConsult verbindet Bewährtes mit Innovation. Wir
          setzen auf eine Kombination aus etablierten und selbst entwickelten
          Methoden zur Analyse und Selektion von Investmentfonds, teilweise in
          Zusammenarbeit mit einer renommierten Universität entstanden. Dabei
          legen wir größten Wert auf Unabhängigkeit, Transparenz und
          Nachvollziehbarkeit in allen unseren Prozessen. Diese Prinzipien
          bilden das Fundament unserer Arbeit und gewährleisten, dass unsere
          Kunden stets fundierte und objektive Beratung erhalten.
        </Typography>
        <Typography variant="body">
          Erfahren Sie mehr über unseren einzigartigen Ansatz.
        </Typography>
        <Box display="flex" gap={2} alignItems="center" pt={2}>
          <Button
            variant={ButtonVariant.SECONDARY}
            onClick={onContactSalesClick}
          >
            Kontaktieren Sie uns
          </Button>
          {/* <Button variant={ButtonVariant.PRIMARY}>Get started for free</Button> */}
        </Box>
      </Box>
      <StyledRightContainer />
    </StyledContainer>
  );
};
