import { FC } from 'react';
import {
  StyledContainer,
  StyledLegalInfoLink,
  StyledLink,
  StyledNavLink,
  StyledNavLinksContainer,
  StyledTitle,
  StyledTopContainer,
  StyledTypography,
} from './Footer.styled';
import { Box } from '../Box';
import { Typography } from '../Typography';
import { legalInfoLinks, navigationItems } from './config';
import { ReactComponent as Logo } from 'assets/light-logo.svg';
import { COLORS } from 'theme/colors';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { AppPaths } from '../../../urls/frontend';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import { FooterLinksCategory } from 'utils/google-analytics/events/footer';

export const Footer: FC = () => {
  const { pathname } = useLocation();
  const isHomePage = pathname === AppPaths.main;

  const onLinkClicked = (link: string) => {
    TrackGoogleAnalyticsEvent(
      FooterLinksCategory,
      link,
      window.location.pathname,
    );
  };

  return (
    <StyledContainer px={8} pt={8.5} pb={5}>
      <StyledTopContainer display="grid" gridGap={4}>
        <Box display="flex" flexDirection="column" gap={4}>
          {isHomePage ? (
            <Logo />
          ) : (
            <Link smooth to={AppPaths.main}>
              <Logo />
            </Link>
          )}
          <StyledTitle variant="body" color={COLORS.background.secondary}>
            Mehrwert durch unabhängige Fondsanalyse
          </StyledTitle>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="body" color={COLORS.background.secondary}>
              Seidlstr. 26, 80335 München
            </Typography>
            <StyledLink href="tel:+4989244180230">
              +49 (89) 24 41 80230
            </StyledLink>
            <StyledLink href="fax:+4989244180249">
              Fax +49 (89) 24 41 802 49
            </StyledLink>
            <StyledLink href="mailto:research@fondsconsult.de">
              research@fondsconsult.de
            </StyledLink>
          </Box>
        </Box>
        <StyledNavLinksContainer display="grid" gridGap={4}>
          {navigationItems.map((item) => (
            <Box>
              <Typography
                variant="overline"
                color={COLORS.background.secondary}
              >
                {item.categoryName}
              </Typography>
              <Box pt={3} display="flex" flexDirection="column" gap={2}>
                {item.items.map((link) => (
                  <StyledNavLink
                    smooth
                    to={link.link}
                    onClick={() => onLinkClicked(link.label)}
                  >
                    {link.label}
                  </StyledNavLink>
                ))}
              </Box>
            </Box>
          ))}
        </StyledNavLinksContainer>
      </StyledTopContainer>
      <Box
        pt={12}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <StyledTypography variant="body">
          &copy; 2024 FondConsult Research GmBH
        </StyledTypography>
        <Box display="flex" gap={3}>
          {legalInfoLinks.map((link) => (
            <StyledLegalInfoLink to={link.path}>
              {link.label}
            </StyledLegalInfoLink>
          ))}
        </Box>
      </Box>
    </StyledContainer>
  );
};
