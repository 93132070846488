import { FC, useEffect, useState } from 'react';
import { ContactFormProps } from './ContactUsForm.d';
import {
  StyledForm,
  StyledLink,
  StyledTypography,
} from './ContactUsForm.styled';
import { Input } from 'components/common/Input';
import { Textarea } from 'components/common/Textarea';
import { Box } from 'components/common/Box';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { contactUsSchema } from './validators';
import { isValidForm } from 'utils/common';
import { Button } from 'components/common/Button';
import { ButtonVariant } from 'components/common/Button/Button.enums';
import { PhoneInput } from 'components/common/PhoneInput';
import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';
import de from 'react-phone-number-input/locale/de.json';
import { contactUs } from 'services/ContactUs';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import {
  ContactFormCategory,
  ContactFormCompletedAction,
} from 'utils/google-analytics/events/contactForm';
import { AppPaths } from 'urls/frontend';

const defaultCountryCallingCode = {
  value: '+49',
  name: 'Deutschland +49',
  country: 'DE',
};

export const ContactUsForm: FC = () => {
  const [countryCallingCode, setCountryCallingCode] = useState(
    defaultCountryCallingCode,
  );
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ContactFormProps>({
    resolver: yupResolver(contactUsSchema),
    mode: 'onBlur',
  });

  const handleCountryCallingCodeChange = (newCountry: string) => {
    const [newSelectedCountry] = getCountries().filter(
      (country) => country === newCountry,
    );
    if (newSelectedCountry) {
      setCountryCallingCode({
        value: `+${getCountryCallingCode(newSelectedCountry)}`,
        name: `${de[newSelectedCountry]} +${getCountryCallingCode(
          newSelectedCountry,
        )}`,
        country: newSelectedCountry,
      });
    }
  };

  useEffect(() => {
    setValue('phone_number_code', countryCallingCode.value);
  }, [countryCallingCode]);

  const getCountryCodesOptions = () => {
    return getCountries().map((country) => ({
      value: `+${getCountryCallingCode(country)}`,
      name: `${de[country]} +${getCountryCallingCode(country)}`,
      country,
    }));
  };

  const onContactFormSubmit = async (data: ContactFormProps) => {
    const { phone_number_code, ...formData } = data;
    const response = await contactUs({
      ...formData,
      phone_number: `${phone_number_code}${data.phone_number}`,
    });

    if (response.ok) {
      raiseToast.success('Formular erfolgreich gesendet.');
      reset();
      TrackGoogleAnalyticsEvent(
        ContactFormCategory,
        ContactFormCompletedAction,
        window.location.pathname,
      );
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit(onContactFormSubmit)}>
      <Box display="flex" gap={2.5}>
        <Input
          placeholder="Ihr Name"
          {...register('sender_name', {
            required: true,
          })}
          error={!isValidForm(errors) && errors.sender_name?.message}
        />
        <Input
          placeholder="E-Mail-Adresse"
          {...register('sender_email', {
            required: true,
          })}
          error={!isValidForm(errors) && errors.sender_email?.message}
        />
      </Box>
      <Box display="flex" gap={2.5}>
        <PhoneInput
          {...register('phone_number', {
            required: true,
          })}
          placeholder="Telefonnummer"
          codeValue={countryCallingCode}
          setCodeValue={handleCountryCallingCodeChange}
          options={getCountryCodesOptions()}
          error={!isValidForm(errors) && errors.phone_number?.message}
          codeError={!isValidForm(errors) && errors.phone_number_code?.message}
        />
        <Input
          placeholder="Thema"
          {...register('subject', {
            required: true,
          })}
          error={!isValidForm(errors) && errors.subject?.message}
        />
      </Box>
      <Box display="flex" gap={2.5}>
        <Input
          placeholder="Unternehmen"
          {...register('company', {
            required: true,
          })}
          error={!isValidForm(errors) && errors.company?.message}
        />
        <Input
          placeholder="Position"
          {...register('position', {
            required: true,
          })}
          error={!isValidForm(errors) && errors.position?.message}
        />
      </Box>
      <Textarea
        placeholder="Nachricht"
        {...register('body', {
          required: true,
        })}
        error={!isValidForm(errors) && errors.body?.message}
      />
      <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
        <Button variant={ButtonVariant.PRIMARY} type="submit">
          Nachricht senden
        </Button>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <StyledTypography variant="body">
          Durch Klicken stimmen Sie unseren{' '}
          <StyledLink to={AppPaths.impressum}>Impressum</StyledLink>, dem{' '}
          <StyledLink to={AppPaths.disclamer}>Disclaimer</StyledLink>, sowie der{' '}
          <StyledLink to={AppPaths.privacyPolicy}>
            Datenschutzerklärung nach DSGVO
          </StyledLink>
        </StyledTypography>
      </Box>
    </StyledForm>
  );
};
