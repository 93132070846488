import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledContainer = styled(Box)`
  background-color: ${COLORS.background.module};
`;

export const StyledSubtitle = styled(Typography)`
  font-size: 18px;
  margin-bottom: 16px;
  font-weight: 600;
`;

export const StyledContent = styled(Typography)`
  font-size: 16px;
  line-height: 29px;

  & span {
    font-weight: 600;
    color: ${COLORS.typography.main};
  }
`;

export const StyledHeader = styled(Typography)`
  font-size: 36px;
  margin-bottom: 16px;
`;

const linkStyles = css`
  color: ${COLORS.typography.body};
  transition-duration: 0.2s;

  &:hover {
    color: ${COLORS.accent.primary};
    text-decoration: none;
  }
`;

export const StyledInnerLink = styled(Link)`
  ${linkStyles}
`;

export const StyledLink = styled.a`
  ${linkStyles}
`;
