import { FC, useEffect } from 'react';
import { Footer } from '../../components/common/Footer';
import { Helmet } from 'react-helmet';
import { Button } from 'components/common/Button';
import { useNavigate } from 'react-router-dom';
import { AppPaths } from 'urls/frontend';
import { Header } from 'components/common/Header';
import {
  StyledContainer,
  StyledHeader,
  StyledSubHeader,
} from './NotFoundPage.styled';
import { Box } from 'components/common/Box';

export const NotFoundPage: FC = () => {
  const navigate = useNavigate();

  const handleButton = () => {
    navigate(AppPaths.main);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Seite nicht gefunden — FondsConsult</title>
        <meta
          name="description"
          content="Seite nicht gefunden — FondsConsult"
        />
      </Helmet>
      <StyledContainer>
        <Header />
      </StyledContainer>
      <StyledContainer>
        <Box
          pt={9.4}
          pb={4.7}
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={2}
        >
          <StyledHeader variant="h1">
            404 Fehler: Seite nicht gefunden
          </StyledHeader>
          <StyledSubHeader variant="body">
            Leider können wir die von Ihnen gesuchte Seite nicht finden.
          </StyledSubHeader>
          <Button onClick={handleButton}>Zur Startseite gehen</Button>
        </Box>
      </StyledContainer>
      <Footer />
    </>
  );
};
