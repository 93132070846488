import { SEO } from 'components/common/SEO';
import { FC, useEffect } from 'react';
import { content, description, imageUrl, title } from './config';
import { Footer } from 'components/common/Footer';
import { Header } from 'components/common/Header';
import {
  StyledContainer,
  StyledContent,
  StyledHeader,
  StyledItem,
  StyledItemsList,
  StyledSubtitle,
} from './DisclaimerPage.styled';
import { Box } from 'components/common/Box';
import { COLORS } from 'theme/colors';

export const DisclaimerPage: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO
        title={title}
        description={description}
        imageUrl={imageUrl}
        content={content}
      />
      <StyledContainer>
        <Header />
      </StyledContainer>
      <StyledContainer>
        <Box
          pt={3}
          pb={12}
          pl={8}
          pr={30}
          display="flex"
          flexDirection="column"
          gap={4}
        >
          <StyledHeader variant="h1">Disclaimer</StyledHeader>

          <Box>
            <StyledSubtitle variant="body" color={COLORS.typography.main}>
              Haftungserklärung (Disclaimer)
            </StyledSubtitle>
            <StyledContent variant="body" color={COLORS.typography.description}>
              Haftungserklärung (Disclaimer), Pflichtangaben nach § 34 b
              Wertpapierhandelsgesetz (WpHG) und mögliche Interessenkonflikte
              (Disclosures) für die von FondsConsult Research GmbH
              (FondsConsult) erstellten und öffentlich verbreiteten
              Finanzanalysen.
            </StyledContent>
            <Box pt={2}>
              <StyledContent
                variant="body"
                color={COLORS.typography.description}
              >
                Die Marktmissbrauchsverordnung und die Delegierte Verordnung
                2016/958 schreiben vor, dass bei der Erstellung von
                Anlagestrategie- und Anlageempfehlungen (früher
                „Finanzanalysen“) Mindestanforderungen einzuhalten sind.
                FondsConsult Research GmbH erstellt Analysen für Investmentfonds
                entsprechend den Vorgaben. In Deutschland ist FondsConsult
                Research GmbH bei der Bundesanstalt für
                Finanzdienstleistungsaufsicht (BaFin), Marie-Curie-Str. 24-28 in
                60439 Frankfurt am Main, registriert. FondsConsult Research GmbH
                hat weder eine Registrierung in Großbritannien noch bei einer
                US-Behörde.
              </StyledContent>
            </Box>
            <Box pt={2}>
              <StyledContent
                variant="body"
                color={COLORS.typography.description}
              >
                Die nachfolgenden Erläuterungen informieren den Anleger über die
                gesetzlichen Vorgaben, die bei der Erstellung von Finanzanalysen
                zu beachten sind.
              </StyledContent>
            </Box>
          </Box>

          <Box>
            <StyledContent variant="body" color={COLORS.typography.description}>
              Erläuterung des Fondsratings:
            </StyledContent>
            <StyledItemsList>
              <StyledItem>
                Buy: Die Kaufempfehlung „Buy“ ist immer als relative Empfehlung
                innerhalb der jeweiligen Anlageklasse zu betrachten. Somit
                impliziert eine „Buy-Empfehlung“ eine bestimmte Charakteristik,
                die sich von Vergleichsprodukten abhebt (z.B. Performance).
                „Buy“ stellt immer die Erstempfehlung dar.
              </StyledItem>
              <StyledItem>
                Hold: Im Falle bedeutender Änderungen bei „Buy“-Empfehlungen
                werden Fonds auf „Hold“ gestellt (z.B. Managerwechsel ohne
                nachvollziehbare Nachfolgeregelung). In diesem Fall sollten
                keine Neukäufe getätigt, die Position aber auch noch nicht
                verkauft werden.
              </StyledItem>
              <StyledItem>
                Switch: Bei nachhaltiger Verschlechterung quantitativer
                Ergebnisse (in der Regel mehr als sechs Monate) oder
                gravierenden Ereignissen (z.B. vorsätzlich unrichtige Angaben)
                erfolgt die Empfehlung „Switch“, d.h. sofortiger Tausch des
                Fonds.
              </StyledItem>
            </StyledItemsList>
          </Box>

          <Box>
            <StyledSubtitle variant="body" color={COLORS.typography.main}>
              Haftungserklärung
            </StyledSubtitle>
            <StyledContent variant="body" color={COLORS.typography.description}>
              Die Analysen in Form von Fondsportraits werden von der
              FondsConsult Research GmbH („FondsConsult“) unabhängig von den
              jeweiligen Fondsgesellschaften erstellt. Die in den Analysen
              enthaltenen Meinungen sind allein diejenigen der FondsConsult und
              enthalten keine marktbezogenen Prognosen. Die Informationen und
              Meinungen sind datumsbezogen und können sich jederzeit ohne
              vorherige Ankündigung ändern. FondsConsult hat den Inhalt der
              Analysen auf der Grundlage von allgemein zugänglichen Quellen
              erstellt, die als zuverlässig gelten, diese aber nicht unabhängig
              überprüft. Daher wird die Ausgewogenheit, Genauigkeit,
              Vollständigkeit und Richtigkeit der in diesem Dokument enthaltenen
              Informationen oder Meinungen weder ausdrücklich gewährleistet noch
              ein solche Gewährleistung hierdurch impliziert. Der Empfänger
              dieses Dokumentes sollte sich auf diese Informationen oder
              Meinungen nicht verlassen. FondsConsult übernimmt keine
              Verantwortung oder Haftung für einen Schaden, der sich aus einer
              Verwendung dieses Dokumentes oder der darin enthaltenen Angaben
              oder der sich anderweitig im Zusammenhang damit ergibt. Eine
              Investitionsentscheidung sollte auf der Grundlage des offiziellen
              Verkaufsprospektes erfolgen und auf keinen Fall auf der Grundlage
              dieser Analysen. Dieses Dokument stellt weder ein Angebot noch
              eine Einladung zum Kauf eines Investmentfonds dar, noch bildet
              dieses Dokument oder die darin enthaltenen Informationen eine
              Grundlage für eine vertragliche oder anderweitige Verpflichtung
              irgendeiner Art.
            </StyledContent>
            <Box pt={2}>
              <StyledContent
                variant="body"
                color={COLORS.typography.description}
              >
                Die Finanzanalysen von FondsConsult unterliegen dem
                Urheberrecht. Ohne die Zustimmung von FondsConsult darf keine
                der in den Analysen enthaltenen Meinungen kopiert oder auf
                andere Weise vervielfältigt, gespeichert oder in irgendeiner
                Form teilweise oder komplett weiterverbreitet werden. Zitate aus
                den Analysen sind mit einer Quellenangabe zu versehen. Jede
                darüber hinaus gehende Nutzung bedarf der vorherigen
                schriftlichen Zustimmung durch FondsConsult.
              </StyledContent>
            </Box>
            <Box pt={2}>
              <StyledContent
                variant="body"
                color={COLORS.typography.description}
              >
                Dieses Dokument ist in Großbritannien nur zur Verteilung an
                Personen bestimmt, die in Art. 11(3) des Financial Services Act
                1986 (Investment Advertisement), Exemptions Order 1996 (in der
                jeweils geltenden Fassung) beschrieben sind, und darf weder
                direkt noch indirekt an einen anderen Kreis von Personen
                weitergeleitet werden. Weder dieses Dokument noch eine Kopie
                hiervon darf in den Vereinigten Staaten von Amerika, nach Kanada
                oder nach Japan oder in ihre jeweiligen Territorien oder
                Besitzungen geschickt, gebracht oder verteilt werden, noch darf
                es an eine US-Person im Sinne der Bestimmungen des US Securities
                Act 1933 oder an Personen mit Wohnsitz in Kanada oder Japan
                verteilt werden. Die Verteilung dieses Dokumentes in anderen
                Gerichtsbarkeiten kann durch Gesetz beschränkt sein und
                Personen, in deren Besitz dieses Dokument gelangt, sollten sich
                über etwaige Beschränkungen informieren und diese einhalten.
                Jedes Versäumnis, diese Beschränkungen zu beachten, kann eine
                Verletzung der geltenden Wertpapiergesetze darstellen.
              </StyledContent>
            </Box>
            <Box pt={2}>
              <StyledContent
                variant="body"
                color={COLORS.typography.description}
              >
                Pflichtangaben nach § 34 b Wertpapiergesetz
              </StyledContent>
            </Box>
            <Box pt={2}>
              <StyledItemsList>
                <StyledItem>
                  Wesentliche Informationsquellen:
                  <p>
                    Wesentliche Informationsquellen für die Erstellung dieses
                    Dokumentes sind Veröffentlichungen der Fondsgesellschaften
                    sowie von in- und ausländischen Medien wie
                    Informationsdienste (z.B. Morningstar Direct, FVBS u.a.),
                    Wirtschaftspresse (z.B. Börsenzeitung, Handelsblatt,
                    Frankfurter Allgemeine Zeitung, u.a.), Fachpresse,
                    veröffentlichte Statistiken.
                  </p>
                </StyledItem>
                <StyledItem>
                  Zusammenfassung der Bewertungsgrundlagen:
                  <p>
                    Im Rahmen der Fondsanalysen werden quantitative und
                    qualitative Bewertungsmethoden herangezogen. Zu den
                    quantitativen Parametern gehören beispielsweise Performance
                    über verschiedene Zeiträume, Volatilität, max. Verlust etc.
                    Zu den qualitativen Parametern gehören beispielsweise die
                    Einschätzung des Investmentprozesses, des Managements etc.
                  </p>
                </StyledItem>
                <StyledItem>
                  Sensitivität der Bewertungsparameter:
                  <p>
                    Die zur Fondsbewertung herangezogenen Parameter sind mit
                    Risiken behaftet. Diese können sich jederzeit ohne vorherige
                    Ankündigung ändern. Unabhängig von der verwendeten
                    Bewertungsmethode besteht ein deutliches Risiko, dass sich
                    der bewertete Fonds schlechter als der Kategoriedurchschnitt
                    oder ein Vergleichsindex entwickeln kann. Zu den Risiken
                    gehören auch unvorhergesehene Änderungen innerhalb der
                    Fondsgesellschaft.
                  </p>
                </StyledItem>
              </StyledItemsList>
            </Box>

            <Box pt={2}>
              <StyledContent
                variant="body"
                color={COLORS.typography.description}
              >
                Zeitliche Bedingungen vorgesehener Aktualisierungen
              </StyledContent>
            </Box>

            <Box pt={2}>
              <StyledContent
                variant="body"
                color={COLORS.typography.description}
              >
                Fondsportraits werden in der Regel in einem Turnus von einem
                Monat aktualisiert. Änderungen bei den Fondsgesellschaften, die
                einen wesentlichen Einfluss auf die Performancedes Fonds haben
                können, können jedoch jederzeit zu einer Aktualisierung der
                Fondsportraits und Änderungen des Fondsratings führen.
              </StyledContent>
            </Box>

            <Box pt={2}>
              <StyledContent
                variant="body"
                color={COLORS.typography.description}
              >
                Interne organisatorische und regulative Vorkehrungen zur
                Prävention oder Behandlung von Interessenkonflikten
              </StyledContent>
            </Box>

            <Box pt={2}>
              <StyledContent
                variant="body"
                color={COLORS.typography.description}
              >
                Es bestehen keine Beteiligungen oder sonstige
                Interessenkonflikte der FondsConsult an Werten des analysierten
                Fonds. Mitarbeiter/-innen von FondsConsult, die mit der
                Erstellung und/oder Darbietung von Finanzanalysen befasst sind,
                sind als Mitarbeiter mit besonderer Funktion eingestuft. Alle
                Mitarbeiter haben eine hausinterne Vereinbarung über
                Insiderrichtlinien unterzeichnet.
              </StyledContent>
            </Box>

            <Box pt={2}>
              <StyledContent
                variant="body"
                color={COLORS.typography.description}
              >
                In selten Fällen hat FondsConsult und die Fondsgesellschaft des
                bewerteten Fonds eine Vereinbarung zur Erstellung des
                Fondsporträts getroffen. Die Bewertung des Fonds erfolgt jedoch
                unabhängig hiervon durch das FondsConsult Investment Committee,
                das sich einzig an der geltenden Geschäftsordnung sowie der
                Unabhängigkeits- und Transparenzerklärung orientiert. Diese
                Richtlinien gewährleisten durch präzise Vorgaben zur Vermeidung
                von Interessenkonflikten die Integrität und Objektivität der
                Bewertungsprozesse. Aus Gründen der Transparenz benennt
                FondsConsult dies auch auf den Fondspotrait der betroffene Fonds
                und führt dies als möglichen Interessenskonflikt auf.
              </StyledContent>
            </Box>

            <Box pt={2}>
              <StyledContent
                variant="body"
                color={COLORS.typography.description}
              >
                Mit Annahme der Finanzanalyse akzeptiert der Empfänger die
                Verbindlichkeit der vorstehenden Beschränkungen.
              </StyledContent>
            </Box>
          </Box>
        </Box>
      </StyledContainer>
      <Footer />
    </>
  );
};
