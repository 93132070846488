import { FC, useEffect, useState } from 'react';
import { MainScreen } from 'components/MainPage/MainScreen';
import { OurApproach } from 'components/MainPage/OurApproach';
import { Studies } from 'components/MainPage/Studies';
import { Recommendations } from '../../components/MainPage/Recommendations';
import { Footer } from '../../components/common/Footer';
import { Header } from '../../components/common/Header';
import { StyledFixedHeaderContainer } from './MainPage.styled';
import { content, description, imageUrl, title } from './config';
import { SEO } from 'components/common/SEO';

export const MainPage: FC = () => {
  const [showFixedHeader, setShowFixedHeader] = useState(false);

  const handleScroll = () => {
    const section = document.querySelector('.about-us');
    if (section) {
      const sectionTop = section.getBoundingClientRect().top + window.scrollY;
      const offset = window.scrollY;

      if (offset >= sectionTop) {
        setShowFixedHeader(true);
      } else {
        setShowFixedHeader(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO
        title={title}
        description={description}
        imageUrl={imageUrl}
        content={content}
      />
      <MainScreen />
      {showFixedHeader && (
        <StyledFixedHeaderContainer showFixedHeader={showFixedHeader}>
          <Header />
        </StyledFixedHeaderContainer>
      )}
      <OurApproach />
      <Studies />
      <Recommendations />
      <Footer />
    </>
  );
};
