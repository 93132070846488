import { HeaderSection } from 'components/common/HeaderSection';
import { FC, useEffect, useState } from 'react';
import HeaderBackground from 'assets/backgrounds/methotology.png';
import { Footer } from 'components/common/Footer';
import { ContactSection } from 'components/common/ContactSection';
import { MainSection } from 'components/Methodology/MainSection';
import { Header } from 'components/common/Header';
import { StyledFixedHeaderContainer } from './Methodology.styled';
import { SEO } from 'components/common/SEO';
import { content, description, imageUrl, title } from './config';

export const Methodology: FC = () => {
  const [showFixedHeader, setShowFixedHeader] = useState(false);

  const handleScroll = () => {
    const section = document.querySelector('.main-section');
    if (section) {
      const sectionTop = section.getBoundingClientRect().top + window.scrollY;
      const offset = window.scrollY;

      if (offset >= sectionTop) {
        setShowFixedHeader(true);
      } else {
        setShowFixedHeader(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO
        title={title}
        description={description}
        imageUrl={imageUrl}
        content={content}
      />
      <HeaderSection
        label="Methodik"
        description="Umfassende, datengestützte Auswertung für fundierte Investitionsentscheidungen"
        backgroundUrl={HeaderBackground}
      />
      {showFixedHeader && (
        <StyledFixedHeaderContainer showFixedHeader={showFixedHeader}>
          <Header expandableMenuVariant="secondary" />
        </StyledFixedHeaderContainer>
      )}
      <MainSection />
      <ContactSection />
      <Footer />
    </>
  );
};
