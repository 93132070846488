import { FC, useEffect } from 'react';
import {
  StyledContainer,
  StyledFormContainer,
  StyledHeader,
  StyledSubHeader,
} from './Contacts.styled';
import { Header } from 'components/common/Header';
import { ContactSection } from 'components/common/ContactSection';
import { Footer } from 'components/common/Footer';
import { ContactUsForm } from 'components/Contacts/ContactUsForm';
import { Box } from 'components/common/Box';
import { SEO } from 'components/common/SEO';
import { content, description, imageUrl, title } from './config';

export const Contacts: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO
        title={title}
        description={description}
        imageUrl={imageUrl}
        content={content}
      />
      <StyledContainer>
        <Header />
      </StyledContainer>
      <StyledContainer>
        <Box
          pt={9.4}
          pb={4.7}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <StyledHeader variant="h1">Kontakt</StyledHeader>
          <StyledSubHeader variant="body">
            Kontaktieren Sie uns über das untenstehende Formular und wir werden
            uns umgehend bei Ihnen melden
          </StyledSubHeader>
        </Box>
        <StyledFormContainer pb={25}>
          <ContactUsForm />
        </StyledFormContainer>
      </StyledContainer>
      <ContactSection />
      <Footer />
    </>
  );
};
